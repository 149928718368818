.legalDisclaimer{
    color: #B3B3B3;
    font-size: 12px;
    a {
        color: #B3B3B3;
    }
}
.legalSection{
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    .discount {
        max-width: initial;
    }
    @media screen and (max-width: 500px) {
        .completed {
            background: var(--primary);
            margin: initial;
            border-radius: inherit;
            color: white;
            position: relative;
            .remindColor{
                fill: white;
            }
            &:after{
                content: "";
                background-image: url("../../../assets/images/backgroundimg.svg");
                bottom: 0;
                position: absolute;
                stroke: red;
                right: 0;
                width: 80px;
                height: 78px;
                background-repeat: no-repeat;
            }
            div{
                display: block;
                font-size: 12px;
                padding: 0 10px;
                svg {
                    display: none;
                }
                button{
                    color: var(--primary);
                    background: white;
                    font-size: 14px;
                    border-radius: 32px;
                    padding: 5px 10px;
                    margin: 11px 0;
                    font-weight: 700;
                }
            }
        }
    }
}

.legal {
    margin-top: 12px;
    border-radius: 40px;
    margin: 0 auto;
    @media screen and (max-width: 500px) {
        margin-top: initial;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
    }
    img {
        width: 100%;
        margin-left: 107px;
        @media screen and (max-width: 520px) {
            margin-left: initial;
        }
    }
    .desktopImg {
        display: block;
        @media screen and (max-width: 500px) {
            display: none;
        }
    }
    .mobileImg {
        display: none;
        @media screen and (max-width: 500px) {
            display: block;
        }
    }
}