/**
 * Kangaroo Checkoout Bootstrap theme
 * read more about theming here:
 * https://getbootstrap.com/docs/4.5/getting-started/theming/
 *
 * view all bootstrap variables:
 * https://github.com/twbs/bootstrap/blob/v4.5.0/scss/_variables.scss
 */

// Colors
$primary: #4A3AFF;

// Grid
$grid-gutter-width: 24px;

// Default bootstrap breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px // our container max-width,,,,,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px,
);

$font-size-root: 16px;
$font-size-base: 1rem;

:root {
  --tt-bg-color: #e2e2e2;
  --white-teal: $white-teal;
  --primary: #4A3AFF;
}

.primary-btn{
  width: 100%;
  display: block!important;
  max-width: 270px;
  text-align: center;
  background-color: var(--primary)!important;
  color: white!important;
  border-radius: 50px!important;
  font-family: inherit!important;
  margin: 10px auto 35px auto !important;
  box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
  border: none;
}

.block-space {
  margin-top: 35px!important;
}

.custom-css-outlined-input{
  border-radius: 50px!important;
}

@import '~bootstrap/scss/bootstrap';