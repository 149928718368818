nav {
    padding: 40px 0;
    margin: 0 auto;
    width: 100%;
    border-bottom: 1px solid #EFF0F7;
}

.navbar-brand img {
    max-width: 170px;
    width: 100%;
    max-height: 66px;
    @media screen and (max-width: 500px) {
        max-width: 127.5px;
    }
}

.navbar{
    padding: 40px 0!important;
}

.mobile-nav div {
    border-top: 1px solid #F2F2F2;
    cursor: pointer;
    width: 100%;
    padding: 20px 0;
    text-align: right;
    padding-right: 26px;
    color: var(--primary);
    font-size: 18px;
    font-weight: 400;
}

.desktop.offcanvas-body{
    display: none;
}

@media (min-width: 992px){
    .mobile{
        display: none!important;
    }
    .desktop{
        display: show;
    }
}

.top-nav .container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.userInfo {
    color: white;
    background: var(--primary);
    padding: 18px 53px;
    border-radius: 76px;
    font-size: 18px;
    cursor: pointer;
}