.formData{
    padding-top: 50px;
    margin: 0 auto;
    text-align: center;
}

.form-control{
    border:2px solid #ced4da;
    padding-left: 28px!important;
    padding-right: 56px!important;
}

button:disabled, button[disabled] {
    opacity: .8;
}

.form-floating > label{
    color: #6F6C90;
    padding-left: 28px!important;
}

input {
    border-radius: 50px!important;
}

.discountList {
    max-width: 500px;
    margin: 0 auto;
    .discountItems {
        display: block;
        background: #FFFFFF;
        border-radius: 16px;
        margin: 7px 0px;
        text-align: left;
        padding: 7px 0;
        input[type="checkbox"]{
            -webkit-appearance: none;
            appearance: none;
            display: none;
        }
        span {
            padding: 13px 0 10px 19px;
            font-size: 15px;
            display: block;
            border: 1px solid #F4F4F4;
            box-shadow: 0px 2px 6px rgb(31 37 89 / 6%);
            border-radius: 16px;
        }
        & :checked ~ span {
            border: 1px solid var(--primary);
            color: var(--primary);
            background-color: #F1EEFF;
            .label{
              font-weight: bold;
            }
        }
    }
}