.aconfirmInfo {
    padding: 40px 72px;
    text-align: center;
    color: #170F49;
    background: #FFFFFF;
    border-radius: 40px;
    @media screen and (max-width: 768px) {
        padding: 20px 22.6px 21px 22.5px;
    }
    .partnerSaving {
        font-weight: 700;
        font-size: 1.688rem;
        margin-bottom: 11px;
    }
    .savingsText {
        color: #292D32;
        font-weight: 700;
        font-size: 3.125rem;
        @media screen and (max-width: 768px) {
            font-size: 1.525rem;
        }
    }
    .estimate {
        font-size: 3.125rem;
        color: #A0A3BD;
        display: grid;
        grid-template-areas:
          'left right';
        gap: 10px;
        @media screen and (max-width: 768px) {
            font-size: 30px;
            grid-template-areas:
            'top' 'bottom';
            gap: 0px;
        }

        .value {
            color: var(--primary);
            font-size: 4.25rem;
            font-weight: 800;
            grid-area: left; 
            text-align: right; 
            @media screen and (max-width: 768px) {
                font-size: 60px;
                grid-area: top; 
                text-align: center; 
            }
        }
        .label {
            color: var(--primary);
            opacity: 50%;
            grid-area: right; 
            text-align: left;
            width: 60px;
            word-break: break-word;
            font-size: 20px;
            line-height: 16px;
            margin-top: 40px;
            @media screen and (max-width: 768px) {
                width: 100%;
                grid-area: bottom; 
                text-align: center; 
                margin-top: -20px;
            }
        }
    }
    .topDisclaimer {
        font-size: 0.875rem;
        color: #6F6C90;
    }
    .policyRate {
        padding: 6px 0;
        max-width: 520px;
        margin: 0 auto 10px;
    }
    .bottomDisclaimer {
        color: #A0A3BD;
        font-size: 0.75em;
        max-width: 524px;
        margin: 0 auto;
    }

    .topDisclaimer{
        font-size: 10px;
        color: #999994;
    }
    .primary-btn{
        margin: 13px auto !important;
    }
}

.checkbox{
    position: relative;
    display: block;
    top: -8px;
}



.bottomDisclaimer .input-error {
    margin-left: 33px;
    text-align: left;
}

.aphotoBtn {
    max-width: 552px;
    font-size: 12px;
    display: flex;
    align-items: baseline;
    justify-items: baseline;
    position: relative;
    margin: 10px auto 0px;
    padding: 0px 10px 0px 10px;
    label{
        color: #A0A3BD;
        display: flex;
        align-items: baseline;
        flex-direction: row-reverse;
        .photoDisclaimer{
            margin-left: 33px;
            text-align: left;
            font-size: 12px;
            line-height: 20px;
            font-weight: 300;
            margin-bottom: 0px;
            line-height: 13.64px;
            @media screen and (max-width: 768px) {
                font-size: 10px;
            }
            a{
                text-decoration: none;
                color: var(--primary);
            }
        }
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #fff;
        border: 1px solid var(--primary);
        border-radius: 9px;
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    input:checked ~ .checkmark:after {
        display: block;
    }
    input:checked ~ .checkmark {
        background-color: var(--primary);
    }
}

.asavings{
    max-width: 800px;
    width: 100%;
}

.asaving-container{
    border-radius: 20px;
    margin-top: 45px;
    @media (max-width:768px) {
    }

    @media (min-width:768px) and (max-width:1024px) {
    }

    @media (min-width:1024px) {
        filter: drop-shadow(0px 2px 10px rgba(8, 15, 52, 0.4));
    }
    
}

.calculator {
    background: #FFFFFF;
    border: 2px solid #F5F5F5;
    border-radius: 28px;
    margin-top: 22px;
}

.calculator-container {
    padding: 16px 44px;
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
    }
}
.flex {
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 400px) {
        padding: 30px 15px;
    }
    @media (min-width:768px) and (max-width:1024px) {
        //display: initial;
    }
}

.acol-one {
    display: flex;
    align-items: flex-start;
    float: right;
    margin: -35px 4px -1px 0px;
    @media (min-width:768px) and (max-width:1024px) {
        justify-content: center;
    }
    img {
        max-width: 60px;
        margin-right: 15px;
    }
    @media screen and (max-width: 768px) {
        svg{
            max-width: 31px;
            height: 29px; 
        }
    }
    .small-block{
        max-width: 240px;
        @media (min-width:768px) and (max-width:1024px) {
            max-width: initial;
            gap: 0 7px;
            justify-content: center;
        }
        p {
            @media screen and (max-width: 768px) {
                margin-block-start: 0px;
                margin-block-end: 0px;
            }
        }
        .saving-header{
            font-weight: 700;
            font-size: 27px;
            margin-bottom: inherit;
            line-height: initial;
            @media screen and (max-width: 768px) {
                font-size: 15px;
            }
        }
        .saving-text{
            font-weight: 400;
            font-size: 18px;
            @media screen and (max-width: 768px) {
                font-size: 12px;
                margin-bottom: 17px;
            }
        }
    }
}


.adesktopBtn{
    display: none;
}

.amobileBtn{
    display: block;
}

@media screen and (max-width: 768px) {
    .adesktopBtn{
        display: none;
    }
    .amobileBtn{
        display: block;
        margin-top: 18px;
        .styledBtn{
            padding: 14px 0 14px;
            max-width: 300px;
            font-size: 18px;
        }
    }
}

.astyledBtn {
    display: block;
    margin-top: 8px;
    padding: 10px 0 10px;
    max-width: 300px;
    font-size: 18px;
    margin: 8px auto 18px auto !important;
}

.discount-ready {
    max-width: 562px;
    margin: 0 auto;
    background: var(--primary);
    color: white;
    font-weight: 700;
    font-size: 27px;
    text-align: center;
    padding: 10px;
    border-radius: 40px 40px 0 0;
    position: relative;
    &:after{
        height: 0px;
        width: 62px;
        border: none;
        border-top: 22px solid var(--primary);
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        content: "";
        position: absolute;
        bottom: -22px;
        left: 44%;
        @media screen and (max-width: 768px) {
            border-top: 20px solid var(--primary);
            border-left: 35px solid transparent;
            border-right: 35px solid transparent;
            bottom: -20px;
            left: 33%;
        }
    }
    @media screen and (max-width: 768px) {
        max-width: 188px;
        font-size: 12px;
    }
}

.policyRate {
    max-width: 450px;
    width: 100%;
    position: relative;
    @media (min-width:768px) and (max-width:1024px) {
        margin: 0 auto;
    }
    .form-control {
        position: relative;
        padding-left: 32px!important;
        padding-right: 52px;
    }
}

.dollar{
    background: url('https://api.iconify.design/uil/dollar-sign.svg') no-repeat center center/contain;
    background-position: 10px 50%;
    background-size: 20px 25px;
    background-repeat: no-repeat;
}

.amountSaved {
    margin-left: 30px;
    padding-top: 9px;
}

.savingDisclaimer {
    color: #6F6C90;
    font-size: 12px;
    line-height: initial;
    margin: 20px 5px 16px;
}

.saver {
    color: #263238;
    font-size: 18px;
    padding-top: 6px;
    font-weight: 700;

    .amount {
        display: grid;
        grid-template-areas:
          'left right';
        gap: 10px;
    }
    .value {
        color: var(--primary);
        font-weight: bold;
        font-size: 20pt;
        grid-area: left; 
        text-align: right; 
    }
    .label {
        color: var(--primary);
        opacity: 50%;
        grid-area: right; 
        text-align: left;
        width: 30px;
        word-break: break-word;
        font-size: 12px;
        font-weight: normal;
        line-height: 12px;
        margin-top: 8px;
    }
}

.toggle-btn{
    display: inline-block;
    border-radius:111.254px;
    padding: 4px 0;
    position: relative;
    text-align: center;
    background:#DEDCF6; 
}

.atoggle {
    margin: 10px 0px 14px 0px;
}

input[type="radio"].atoggle {
    display: none;
    position: inherit;
    right: 4px;
    top: 3px;
    & + label{
        cursor: pointer;
        min-width: 181px;
        @media screen and (max-width: 768px) {
            min-width: 152px;
            font-size: 15px;
        }
        &:after{
            content: "";
            height: 100%;
            z-index:1000;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
    &.toggle-left + label {
        border-right: 0;
        &:after{
            left: 100% 
        }
    }
    &.toggle-right + label{
        margin-left: -20px;
        @media screen and (max-width: 768px) {
            margin-left: -20px;
        }
        &:after{
            left: -100%;
        }
    }
    &:checked + label {
        cursor: default;
        color: #000;
        border-radius: 111.254px;
        background:white;
        border: 1px solid #000;
        z-index: 1;
        &:after{
            left: 0;
        }
    }
}

.cal-toggle-btn{
    display: inline-block;
    border-radius:111.254px;
    padding: 5px 0;
    position: relative;
    text-align: center;
    background: var(--primary) !important; 
    padding-left: 13px;
    color: white;
}

input[type="button"].calToggle {
    display: none;
    & + label{
        cursor: pointer;
        min-width: 111px;
        font-size: 14px;
        @media screen and (max-width: 768px) {
            min-width: 42px;
            font-size: 15px;
            min-width: 101px;
        }
        &:after{
            content: "";
            height: 100%;
            z-index:1000;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
    &.toggle-left + label {
        border-right: 0;
        &:after{
            left: 100% 
        }
    }
}

.cal_icon{
    //margin-left: 5px;
    position: absolute;
    padding-left: 8px;
    @media (min-width:768px) and (max-width:1024px) {
        margin-left: -85px;
    }
    @media (min-width:1024px) {
        margin-left: 5px;
    }
   
}

.dollar-div{
    width: 80%;
    @media (max-width:768px) {
        width: 80%;
    }

    @media (min-width:768px) and (max-width:1024px) {
        width: 80%;
    }

    @media (min-width:1024px) {
        width: 80%;
    }
}

.perText{
    text-align: center;
    display: block;
    margin-top: 20px;
    font-size: 18px;
}

.claim-bottom{
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0%);
    margin: 0 auto;
    background: white;
    width: 100%;
    border-top: 2px solid #eeeeee;
    z-index: 100
}