.discountProfile {
    max-width: 786px;
    height: 471px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgb(20 20 43 / 15%);
    border-radius: 45px;
    padding: 43px 40px;
    margin: 35px auto;
    @media screen and (max-width: 500px) {
        height: initial!important;
    }
    img {
        max-height: 50px!important;
    }
    .name{
        span {
            font-size: 27px;
            font-weight: bold;
            padding-left: 17px;
            vertical-align: middle;
        }
    }
    .btnGroup {
        display: flex;
        flex-direction: column;
        max-width: 290px;
        margin: 0 auto;
        @media (min-width:300px) and (max-width:1024px) {
            flex-direction: row-reverse;
            align-items: center;
        }
        @media (min-width:768px) and (max-width:1024px) {
            max-width: 641px;
            gap: 0 137px;
        }
        a{
            text-decoration: none;
            button {
                @media screen and (max-width: 768px) {
                    padding: 14px 32px;
                }
                @media (min-width:768px) and (max-width:1024px) {
                    width: 270px;
                }
            }
            @media screen and (max-width: 768px) {
                max-width: 127px;
                font-size: 12px;
            }
        }
        .quoteBtn{
            margin: 0 auto!important;
        }
        .callBtn {
            margin: 0 auto;
            button{
                background: none;
                border: none;
                color: #595956;
                padding: 26px 0;
            }
        }
    }
    .insurerDiscount{
        border: 1px solid #B3B3B3;
        border-radius: 16px;
        max-width: 688px;
        margin: 0 auto;
        .progress-bar{
            color: black;
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            background: #E9E9FB;
            align-items: flex-start;
            padding-left: 29px;
            span {
                position: absolute;
                @media screen and (max-width: 500px) {
                    font-size: 13px;
                }
            }
        }
    }

    .insurerCurrentDiscount{
        border: 1px solid #B3B3B3;
        border-radius: 16px;
        max-width: 688px;
        margin: 0 auto;
        @media screen and (max-width: 500px) {
            border-radius: 5px;
        }
        .progress-bar{
            color: black;
            font-weight: 400;
            font-size: 18px;
            background: #A0A3BD;
            line-height: 30px;
            align-items: flex-start;
            padding-left: 29px;
            span {
                position: absolute;
                @media screen and (max-width: 500px) {
                    font-size: 13px;
                }
            }
        }
    }

    .blk-10{
        margin: 25px auto;
        @media screen and (max-width: 500px) {
            height: 35px!important;
            border-radius: 5px!important;
        }
    }
    
}

.highlight{
    border: 2px solid var(--primary)!important;
    background: #F7F7FC!important;
}

.discount-banner {
    max-width: 294px;
    margin: 0 auto -35px auto;
    background: var(--primary);
    color: white;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    padding: 10px;
    border-radius: 40px 40px 0 0;
    position: relative;
    &:after{
        height: 0px;
        width: 62px;
        border: none;
        border-top: 22px solid var(--primary);
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        content: "";
        position: absolute;
        bottom: -22px;
        left: 39%;
        @media screen and (max-width: 500px) {
            left: 34%;
        }
    }
    @media screen and (max-width: 500px) {
        max-width: 194px;
        font-size: 18px;
    }
}