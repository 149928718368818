.formCenter{
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
        padding: 0 20px;
    }
    .discountSection {
        @media screen and (min-width: 847px) {
            min-width: 750px;
        }
    }
}
.top-section{
    background-color: #EFF0F6;
    padding: 20px 80px 20px 80px;
    border-radius: 20px 20px 0px 0;
}
.boxContent{
    padding: 59px 50px;
    @media screen and (max-width: 768px) {
        padding: 59px 25px;
    }
}
.formHeader{
    margin: 0 auto;
    text-align: center;
    padding: 0px 50px;
    @media screen and (max-width: 500px) {
        padding: initial;
    }
    .mainTitle{
        font-weight: 700;
        font-size: 27px;
        color: #170F49;
        max-width: 666px;
        display: block;
        margin: 0 auto;
        line-height: 35px;
    }
    .subTitle{
        font-size: 18px;
        font-weight: 400;
        color: #6F6C90;
        padding-top: 9px;
        line-height: 30px;
    }
}

@media (min-width:765px) and (max-width:1024px) {
    .formCenter{
        padding: 0 20px;
    }
}