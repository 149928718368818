.discount {
    background: #F7F7FC;
    max-width: 987px;
    width: 100%;
    padding: 46px 25px;
    border-radius: 0 0 40px 40px;
    .discountContainer{
        max-width: 674px;
        text-align: center;
        margin: 0 auto;
        .title{
            font-size: 27px;
            color: #170F49;
        }
        .content{
            padding:15px;
            color: #6F6C90;
            font-size: 18px;
        }
        fieldset{
            display: flex;
            gap: 0 20px;
            justify-content: center;
            @media screen and (max-width: 500px) {
                display: initial;
            }
        }
    }
    .btn-secondary{
        border: none;
        background: none;
        color: var(--primary);
    }
    .claimBtn {
        color: #595956;
        background: none;
        border: none;
        font-weight: 700;
    }
}

.saveDirect {
    flex-direction: column;
    max-width: 430px;
    margin: 0 auto;
}

.rmdp-header {
    display: none;
}

.showHide {
    border: none;
    background: none;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: var(--primary)!important;
    box-shadow: 0 0 3px #8798ad;
    color: #fff!important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: #E9E9FB!important;
    color: var(--primary)!important;
}

.rmdp-container {
    display: inherit!important;
}

.rmdp-month-picker, .rmdp-year-picker{
    background: none!important;
}

.rmdp-ep-arrow[direction=top] {
    display: none;
}

.rmdp-wrapper {
    border-radius: 40px!important;
}

.title {
    font-size: 22px;
    color: #170F49;
    padding-top: 16px;
    @media screen and (max-width: 500px) {
        font-size: 14px;
        padding-top: inherit;
    }
}

.content {
    font-size: 18px;
    color: #6F6C90;
}

.discountDisclaimer .btn-secondary {
    text-align: left;
}

.discountDisclaimer {
    max-width: 371px;
    margin: 0 auto;
    position: relative;
}

.single {
    margin-top: 18.5px;
    .discount{
        border-radius: 40px;
        padding: 46px 97px;
        @media screen and (max-width: 500px) {
            padding: 46px 25px;
        }
    }
}

.discountDisclaimer .speech {
    top: -186px;
    max-width: 402px;
    width: 100%;
    @media screen and (max-width: 500px) {
        top: -201px;
    }
}

.completed{
    background-color: #F7F7FC;
    border: 1px solid #E6E6E6;
    max-width: 1100px;
    margin: 10px auto 10px auto;
    border-radius: 10px 10px;
    padding: 20px 0;
    div {
        display: flex;
        justify-content: center;
        gap: 23px;
        align-items: center;
        font-size: 18px;
        button{
            color:var(--primary);
            background:none;
            border: none;
            font-size: 18px;
        }
    }
}