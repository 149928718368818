.progress{
    background: #B8B1FF;
    border-radius: 0px 10px 10px 0;
}

.containerStyle{
    position: absolute;
    left: 0;
}


.discountContainerStyle {
    max-width: 317px;
    margin: 0 auto;
    border-radius: 64px;
    border: 2px solid #D9DBE9;
    position: relative;
    .progress{
        border-radius: 64px;
        background: #E4E1FF;
        span{
            text-align: center;
            color: #A0A3BD;
            position: absolute;
            width: 100%;
            top: 16%;
            font-size: 20px;
        }
    }
}

/* For Chrome or Safari */
progress::-webkit-progress-bar {
    background-color: #F7F7FC;
    border-radius: 64px;
    border: 2px solid #D9DBE9;
    max-width: 317px;
    margin: 0 auto;
    width: 100%;
    height: 47px;
}

progress::-webkit-progress-value {
    background-color: #B8B1FF !important;
    border-radius: 64px;
}

progress::-moz-progress-bar {
    background-color: #B8B1FF !important;
    border-radius: 64px;
}

/* For IE10 */
progress {
    background-color: #F7F7FC;
    border-radius: 64px;
    border-color: #D9DBE9;
    max-width: 317px;
    margin: 0 auto;
    width: 100%;
    height: 47px;
    display: block;
    overflow: overlay;
}

.top-section section {
    position: relative;
    .bubble {
        top: -127px;
        left: 21%;
    }
}

.progressText {
    position: absolute;
    text-align: center;
    left: 49%;
    top: 22%;
    font-size: 18px;
    color: #A0A3BD;
}