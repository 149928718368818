.discountContainer{
    width: 100%;
    .topSection {
        text-align: center;
        padding: 35px 0 40px 0;
        img{
            max-width: 81px;
            margin-bottom: 35px;
        }
        .heading1{
            line-height: 35px;
            font-size: 38px;
            color: #170F49;
        }
        .heading2{
            font-size: 25px;
            color: #6F6C90;
        }
    }
}

.claimDiscount {
    margin: 0 auto;
    width: 100%;
    max-width: 796px;
}

.claimDiscount {
    margin: 0 auto;
    width: 100%;
    max-width: 1338px;
    text-align: center;
    border-bottom: 1px solid #E6E6E6;
}

.claimedContainer {
    position: relative;
    padding: 15px 0;
    max-width: 1218px;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row-reverse;
    div {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 0 30px;
        @media screen and (max-width: 500px) {
            align-items: center;
        }
        svg {
            @media screen and (max-width: 500px) {
                width: 16px;
                height: 16px;
            }
        }
    }
}

button.close-btn {
    right: 29px;
    border: none;
    background: none;
}

.noOffers{
    text-align: center;
    p{
        font-size: 22px;
    }
}

.discountContainer .claimDiscount .discount {
    margin: 0 auto;
}