footer{
    background: #EDECF6;
    width: 100%;
    margin-top: 43px;
    .container{
        padding: 32px 0;
        text-align: center;
        .links{
            a {
                padding-left: 5px;
                color: var(--bs-body-color);
                text-decoration: none;
                &:after{
                    content: '|';
                    padding-left:5px;
                }
            }
        }
    }
}

footer .container .links a:last-child:after {
    content: none;
}

.copyright {
    line-height: 31px;
}