.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(53, 63,99, 0.6);
    display: flex;
    justify-content: center;
    z-index: 100000;
    align-items: center;
}