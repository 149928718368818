.textData{
    max-width: 360px;
    margin: 0 auto;
    select{
        border: 2px solid #EFF0F6;
        border-radius: 50px;
        padding: 21px 30px 21px 30px;
        gap: 10px;
        color: #6F6C90;
        width: 360px;
    }
}

.person-icon{
    background-image: url(https://dev.meetdory.com/assets/svg/person.svg);
    background-position: 90% 50%;
    background-size: auto;
    background-repeat: no-repeat;
}

.confirmBtn.vector{
    gap: 10px 0;
    svg {
        width: 86px!important;
        height: 61px!important;
    }
}

.tiles.vector{
    gap: 8px 0!important;
    padding: 10px 9px!important;
    svg {
        width: 59px!important;
        height: 59px!important;
    }
}

.email-icon,.agentEmail-icon{
    background-image: url(https://dev.meetdory.com/assets/svg/mail.svg);
    background-position: 90% 50%;
    background-size: auto;
    background-repeat: no-repeat;
}

.addressData{
    .addressFields{
        display: flex;
        margin: 0 auto;
        justify-content: center;
        gap: 0 26px;
        @media screen and (max-width: 500px) {
            flex-wrap: wrap;
            max-width: 250px;
            .form-control {
                max-width: 250px;
            }
        }
    }
    .address-icon{
        background-image: url(https://api.iconify.design/bytesize/location.svg?color=%23b3b3b3);
        background-position: 98% 50%;
        background-size: 25px 25px;
        background-repeat: no-repeat;
        width: 350px;
        padding-right: 33px;
    }

    .apt {
        max-width: 131px;
    }
}

.policyrate{
    max-width: 260px;
}

span.input-error {
    color: red;
    padding: 4px 0;
    display: block;
    font-size: 14px;
}

.photoContainer{
    margin-top: 30px;
    display: block;
}

.border-error {
    border: 2px solid #ff000075!important;
}

.policy {
    display: flex;
}

.styledBtn {
    background-color: var(--primary);
    color: white;
    box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
    border-radius: 50px;
    padding: 20px 40px 21px;
}

.radio_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    width: 168px;
    height: 58px;
    border-radius: 9999px;
    border: 2px solid #d9dbe9;
    input[type=radio]:checked ~ .radio-state {
        background-color: var(--primary);
        color: #f1f3f5;
        font-weight: 500;
        transition: .3s;
        height: 51px;
    }
}


.radio-state {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    width: 76px;
    margin-left: 9px;
    margin-top: 5px;
    margin-right: 9px;
    height: 40px;
    text-align: center;
    border-radius: 9999px;
    overflow: hidden;
    transition: linear .3s;
    color: #6F6C90;
}

input[type=radio] {
    -webkit-appearance: none;
    appearance: none;
    display: none;
}

.confirmBtn {
    margin: 10px auto;
}

.whyPopup {
    padding: 50px 0;
    .title{
        font-size: 27px;
        font-weight: 700;
        padding: 15px;
        color: #170F49;
    }
    .content{
        font-size: 18px;
        color: #6F6C90;
    }
}

.buttonContainer{
    .tiles{
        background: #FFFFFF;
        border: 2px solid #E7E7E7;
        display: flex;
        box-shadow: 0px 2px 6px rgb(31 37 89 / 6%);
        border-radius: 16px;
        padding: 20px 9px;
        max-width: 160px;
        gap: 11px;
        font-size: 15px;
        height: 143px;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
    }
    label{
        position: relative;
        cursor: pointer;
        color: #170F49;
        
        & :checked ~ .tiles {
            border: 1px solid var(--primary);
            color: var(--primary);
            background-color: var(--primary)14!important;
            .label{
              font-weight: bold;
            }
        }
        img{
            display: block;
            padding: 7px 0;
        }
    }
    .boolean{
        max-width: 400px;
        margin: 0 auto;
        label{
            position: relative;
            cursor: pointer;
            color: #170F49;
            
            & :checked ~ .confirmBtn {
                border: 1px solid var(--primary);
                color: var(--primary);
                background-color: #F1EEFF;
                font-weight: bold;
                .label{
                  font-weight: bold;
                }
            }
            .confirmBtn{
                background: #FFFFFF;
                border: 2px solid #E7E7E7;
                display: flex;
                box-shadow: 0px 2px 6px rgb(31 37 89 / 6%);
                border-radius: 16px;
                padding: 20px 9px;
                max-width: 160px;
                height: 143px;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: center;
            }
            img{
                display: block;
                padding: 7px 0;
            }
        }
    }

    .threeCol{
        display: grid;
        grid-template-columns: 160px 160px 160px;
        margin: 0 auto;
        max-width: 597px;
        grid-gap: 30px 0;
        @media screen and (max-width: 500px) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .twoCol{
        display: grid;
        grid-template-columns: 160px 160px;
        margin: 0 auto;
        grid-gap: 30px 25px;
        width: 400px;
    }

    .radio_container{
        & :checked ~ .radio-state {
            border: 1px solid var(--primary);
            color: #fff;
            background-color: var(--primary);
        }
    }

    .row{
        justify-content: center;
        @media screen and (max-width: 768px) {
            gap: 20px 0;
        }
        .buttonIcons{
            display: flex;
            flex-wrap: wrap;
            gap: 11px 3px;
            flex-direction: column;
            justify-content: center;
            max-width: 159px;
            font-size: 15px;
            line-height: 20px;
            svg{
                display: block;
                text-align: center;
                color: var(--primary);
                width: 39px;
                height: 39px;
                margin: 0 auto;
            }
        }
        label {
            position: relative; 
            cursor: pointer;
            color: #170F49;
            & input[type="checkbox"] {
              position: absolute;
              opacity: 0;
            }
            
            & :checked ~ .icon-box {
              border: 1px solid var(--primary);
              color: var(--primary);
              background-color: #F1EEFF;
              .label{
                font-weight: bold;
              }
            }
            
            & .icon-box {
                justify-content: center;
                align-items: center;
                border: 2px solid #E7E7E7;
                box-shadow: 0px 2px 6px rgb(31 37 89 / 6%);
                border-radius: 16px;
                padding: 28px 9px;
                max-width: 160px;
                height: 143px;
                width: 135px;
              .label{
                display: block;
                padding:5px 0;
                word-spacing: 3px;
              }
              
            }
        }
    }
}

.provider{
    text-align: left;
}

.inactive{
    display: none;
}

.active{
    display: block;
}

div#suggestionBox {
    border: 2px solid #ced4da;
    width: 91%;
    max-height: 300px;
    border-radius: 0px 0 40px 40px;
    border-top: none;
    margin: 0px auto;
    padding: 0px 0 20px 0px;
    overflow: hidden;
    position: absolute;
    background: white;
    left: 16px;
    z-index: 10000;
}

div#suggestionBox div {
    padding: 10px 11px;
    cursor: pointer;
    text-align: left;
}

.css-13cymwt-control {
    border: 2px solid #EFF0F6!important;
    border-radius: 40px!important;
    padding: 3px!important;
}

.css-1dimb5e-singleValue{
    text-align: left;
}

.css-1jqq78o-placeholder {
    padding-left: 28px !important;
}

.css-t3ipsp-control {
    border: 2px solid #EFF0F6!important;
    border-radius: 40px!important;
    padding: 3px!important;
    &:hover{
        border: 2px solid var(--primary)!important;
    }
}

.css-1u9des2-indicatorSeparator{
    background: none!important;
}

.form-floating .input-error {
    text-align: left;
    margin-left: 30px;
}

@keyframes spinner {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
         transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}
.spinner {
    height: 70px;
    opacity: 1;
    position: relative;
    transition: opacity linear 0.1s;    
    &::before {
        animation: 1s linear infinite spinner;
        border: solid 3px #eee;
        border-bottom-color: var(--primary);
        border-radius: 50%;
        content: "";
        height: 40px;
        left: 50%;
        opacity: inherit;
        position: absolute;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        transform-origin: center;
        width: 40px;
        will-change: transform;
    }
}