.popup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    z-index: 100000;
    bottom: 0;
    align-items: center;
    .popup-inner{
        border-radius: 40px;
        position: relative;
        padding: 32px;
        width: 100%;
        max-width: 632px;
        text-align: center;
        background-color: #fff;
        h3 {
            font-size: 28px;
            line-height: 34px;
        }
        p {
            font-size: 18px;
            line-height: 30px;
        }
        .close-btn{
            position: absolute;
            top: 14px;
            right: 24px;
            background: none;
            border: none;
            color: #334D80;
            font-size: 24px;
        }
        .popup-buttons button{
            display: block;
            margin: 0 auto;
            max-width: 416px;
            border-radius: 8px;
            font-weight: 700;
            width: 100%;
            font-size: 21px;
            text-transform: uppercase;
        }
        .close-button{
            color: white;
            background: #334D80;
            border: none;
            padding: 23px 0;
            line-height: 21px;
        }
        .cancel-button{
            color: #334D80;
            background: white;
            padding: 23px 0;
            line-height: 21px;
            margin-top: 24px!important;
            border: 1px solid #334D80;
        }
    }
}

.signout{
    position: absolute;
    display: block;
    margin-top: 28px;
    background: #ffffff;
    padding: 13px 50px;
    border-radius: 76px;
    font-weight: bold;
    z-index: 100;
    cursor: pointer;
    color: var(--primary);
    border: 1px solid var(--primary);
}
.signout::before,
.signout::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 110px;
  border: 11px solid transparent;
  border-bottom-color: 11px solid var(--primary);
}

.signout::after {
  left: 111px;
  border: 10px solid transparent;
  border-bottom-color: #ffffff;
}


.bubble {
    position: absolute;
    width: 300px;
    text-align: center;
    line-height: 1.4em;
    margin: 40px auto;
    background-color: #fff;
    border: 2px solid var(--primary);
    border-radius: 30px;
    font-family: sans-serif;
    padding: 20px;
    color: #6F6C90;
    font-size: 15px;
    .close-btn{
        top: -5px;
        right: -1px;
        position: absolute;
        background: var(--primary);
        padding: 6px;
        display: flex;
        border-radius: 100%;
        svg{
            background: var(--primary);
            border-radius: 100%;
            width: 13px;
            height: 13px;
            color: white;
        }
    }
}


.bubble:before,
.bubble:after {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
}

.speech:before {
	left: 30px;
    bottom: -28px;
    border: 13px solid;
    border-color: var(--primary) transparent transparent var(--primary);
}

.speech:after {
	 left: 32px;
    bottom: -24px;
    border: 15px solid;
    border-color: #fff transparent transparent #fff;
}