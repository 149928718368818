.error{
    height: 64vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.timeout {
    .content {
        position: absolute;
        top: 50vh;
        width: 100%;
        text-align: center;
    }
}